import React from 'react'
import { Link } from 'gatsby'

const DienstCTA = () => {
  return (
    <section className="section">
      <div className="container">
        <div className="columns is-centered">
          <div className="shadow bb column is-two-thirds-desktop">
            <div className="has-text-centered p-4">
              Vragen over een van onze diensten?{' '}
              <Link to="/contact">Contacteer ons!</Link>
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}

export default DienstCTA
