import React from 'react'
import PropTypes from 'prop-types'
import { graphql } from 'gatsby'
import { GatsbyImage } from 'gatsby-plugin-image'
import Helmet from 'react-helmet'
import Layout from '../components/Layout'
import DienstCTA from '../components/CTA/DienstCTA'

const DienstTemplate = ({ title, content, diensten }) => {
  return (
    <>
      <section className="section">
        <div className="container">
          <h1 className="is-uppercase">{title}</h1>
          <h2
            className="intro"
            dangerouslySetInnerHTML={{
              __html: content,
            }}
          />
        </div>
      </section>

      <div className="diensten">
        {diensten
          ? diensten.map((dienst, index) => (
              <div
                className={`dienst ${index % 2 == 0 ? 'colored-back' : ''}
                `}
              >
                <section className="section section-ntb">
                  <div className="container">
                    <div
                      className={`columns ${
                        index % 2 !== 0 ? 'row-reverse' : ''
                      }`}
                    >
                      <div className="column is-6 is-flex vcenter flex-col">
                        {dienst.dienstBeeld && (
                          <GatsbyImage
                            image={
                              dienst.dienstBeeld.localFile?.childImageSharp
                                ?.gatsbyImageData
                            }
                            alt={dienst.dienstBeeld.altText}
                          />
                        )}
                      </div>
                      <div className="column is-6">
                        <h2 className="inner-title">{dienst.dienstTitel}</h2>
                        <div
                          className="work-content"
                          dangerouslySetInnerHTML={{
                            __html: dienst.dienstTekst,
                          }}
                        />
                      </div>
                    </div>
                  </div>
                </section>
              </div>
            ))
          : null}
      </div>
      <DienstCTA />
    </>
  )
}

DienstTemplate.propTypes = {
  title: PropTypes.string.isRequired,
  content: PropTypes.string,
  inleiding: PropTypes.string,
}

const Dienst = ({ data, path }) => {
  const { wpDienst: dienst } = data
  const { title } = data.site.siteMetadata

  return (
    <Layout path={path}>
      <Helmet>
        <title>{`${dienst.title} | Dienst | ${title}`}</title>
        <meta name="description" content={dienst.title} />
        <meta name="og:title" content={`${dienst.title} | Dienst | ${title}`} />
        <meta name="og:description" content={dienst.title} />
      </Helmet>
      <DienstTemplate
        title={dienst.title}
        content={dienst.content}
        diensten={dienst.repeatFieldsTextPhoto.diensten}
      />
    </Layout>
  )
}

Dienst.propTypes = {
  data: PropTypes.object.isRequired,
}

export default Dienst

export const dienstQuery = graphql`
  query DienstById($id: String!) {
    site {
      siteMetadata {
        title
      }
    }
    wpDienst(id: { eq: $id }) {
      title
      content
      repeatFieldsTextPhoto {
        diensten {
          dienstTitel
          dienstTekst
          dienstBeeld {
            altText
            localFile {
              childImageSharp {
                gatsbyImageData(
                  quality: 100
                  placeholder: BLURRED
                  layout: FULL_WIDTH
                )
              }
            }
          }
        }
      }
    }
  }
`
